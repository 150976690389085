<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item :label="$t('index.username')" prop="name">
              <el-input :placeholder="$t('index.username')" v-model="formInline.name"></el-input>
            </el-form-item>
            <el-form-item label="设备IMEI" prop="imei">
              <el-input placeholder="设备IMEI" v-model="formInline.imei"></el-input>
            </el-form-item>
            <el-form-item :label="$t('device.vesting')" prop="client_id">
              <el-cascader
                  :options="options"
                  :placeholder="$t('device.vesting')"
                  v-model="formInline.client_id"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchCont" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetBtn" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-button type="primary" @click="addUsers()" v-if="$store.state.pageAction.indexOf('device.add')> -1">
              {{ $t('device.add') }}
            </el-button>
            <el-button :disabled=" unbindIsDisabled " @click="batchUnbind"
                       v-if="$store.state.pageAction.indexOf('user.batch_unbind')> -1">{{ $t('user.batch_unbind') }}
            </el-button>
          </div>
        </div>
      </template>
      <el-table
          :data="listData"
          :default-sort="{prop: 'date', order: 'descending'}"
          @selection-change="tableSelected"
          @sort-change="sortChange"
          border
          ref="userlistTable"
          stripe
          style="width: 100%"
      >
        <el-table-column type="selection" width="55" fixed></el-table-column>
        <!--        <el-table-column :label="$t('order.account')" prop="uid" width="80" fixed></el-table-column>-->
        <el-table-column :label="$t('index.username')" prop="name" width="200" sortable="custom"></el-table-column>
        <el-table-column :label="$t('index.device')" prop="imei" width="150"></el-table-column>
        <el-table-column :label="$t('index.online_status')" prop="is_online" width="200"></el-table-column>
        <el-table-column :label="$t('index.battery_level')" prop="volt" width="200" sortable="custom"></el-table-column>
        <el-table-column :label="$t('user.contact')" prop="mobile" width="120"></el-table-column>
        <el-table-column :label="$t('user.gender')" prop="gender" width="100"></el-table-column>
        <el-table-column :label="$t('user.age')" prop="age" width="100"></el-table-column>
        <el-table-column :label="$t('user.health')" prop="health" width="100"></el-table-column>
        <el-table-column :label="$t('device.vesting')" prop="client_name" width="200"></el-table-column>
        <el-table-column :label="$t('device.creation_time')" prop="create_at" width="200"
                         sortable="custom"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="160" fixed="right">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" size="small"
                       v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{ $t('device.edit') }}
            </el-button>
            <el-popover placement="left" width="500" trigger="click">
              <el-button
                  :disabled="!(scope.row.imei!=='')"
                  @click="healthEdit(scope.row.imei)"
                  size="small"
                  v-if="$store.state.pageAction.indexOf('health.title')> -1"
              >{{ $t('health.title') }}
              </el-button>
              <el-button
                  :disabled="!(scope.row.imei!=='')"
                  @click="locationEdit(scope.row.imei)"
                  size="small"
                  v-if="$store.state.pageAction.indexOf('index.positioning')> -1"
              >{{ $t('index.positioning') }}
              </el-button>
              <el-button
                  :disabled="!(scope.row.imei!=='')"
                  @click="trackEdit(scope.row.imei)"
                  size="small"
                  v-if="$store.state.pageAction.indexOf('detail.trajectory')> -1"
              >{{ $t('detail.trajectory') }}
              </el-button>
              <el-button
                  :disabled="!(scope.row.imei!=='')"
                  @click="fenceEdit(scope.row.imei)"
                  size="small"
                  v-if="$store.state.pageAction.indexOf('fence.title')> -1"
              >{{ $t('fence.title') }}
              </el-button>
              <el-button @click="alarmsetEdit(scope.row.uid)" size="small"
                         v-if="$store.state.pageAction.indexOf('group.alarm_setting')> -1">{{
                  $t('group.alarm_setting')
                }}
              </el-button>
              <el-button @click="usermesEdit(scope.row.uid)" size="small"
                         v-if="$store.state.pageAction.indexOf('fence.userinfo')> -1">{{ $t('fence.userinfo') }}
              </el-button>
              <el-button @click="unbindEdit(scope.row.uid)" size="small"
                         v-if="$store.state.pageAction.indexOf('user.unbind')> -1">{{ $t('user.unbind') }}
              </el-button>
              <!--              <el-button @click="deleteUser(scope.row.uid)" size="small">{{ $t('device.delete') }}</el-button>-->
              <template #reference>
                <el-button size="small">{{ $t('index.more') }}</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog center :title="$t('user.unbind')" top="40vh" v-model="unbindPop" width="30%">
        <span>{{ $t('user.unbind_note') }}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="unbindPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="unbindAffirm" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog center :title="$t('user.edit')" top="5vh" v-model="userinfoPop" width="60%">
        <el-form :model="userForm" label-width="120px" :inline="true" class="demo-form-inline">
          <el-form-item :label="$t('user.gender')" prop="sex">
            <el-radio-group v-model="userForm.gender">
              <el-radio :label="1">{{ $t('user.male') }}</el-radio>
              <el-radio :label="2">{{ $t('user.female') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('user.birthday')" prop="birthday">
            <el-date-picker
                :placeholder="$t('user.birthday')"
                type="date"
                :disabled-date="disabledDate"
                v-model="userForm.birthday"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('user.nation')" label-width="120px" prop="nation">
            <el-input v-model="userForm.nation"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.height')" label-width="120px" prop="height">
            <el-input v-model="userForm.height"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.weight')" label-width="120px" prop="weight">
            <el-input v-model="userForm.weight"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.blood')" label-width="120px" prop="blood_type">
            <el-select :placeholder="$t('user.blood')" v-model="userForm.blood_type">
              <el-option label="A" :value="1"></el-option>
              <el-option label="B" :value="2"></el-option>
              <el-option label="O" :value="3"></el-option>
              <el-option label="AB" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('user.id_card')" label-width="120px" prop="id_card">
            <el-input v-model="userForm.id_card"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.permanent_address')" label-width="120px" prop="permanent_address">
            <el-input v-model="userForm.permanent_address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.work_unit')" label-width="120px" prop="work_unit">
            <el-input v-model="userForm.work_unit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.marital_status')" label-width="120px" prop="marital_status">
            <el-select :placeholder="$t('user.marital_status')" v-model="userForm.marital_status">
              <el-option :label="$t('user.married')" :value="1"></el-option>
              <el-option :label="$t('user.unmarried')" :value="2"></el-option>
              <el-option :label="$t('user.divorce')" :value="3"></el-option>
              <el-option :label="$t('user.widow')" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('user.health')" label-width="120px" prop="health">
            <el-select :placeholder="$t('user.health')" v-model="userForm.health">
              <el-option :label="$t('user.good')" :value="1"></el-option>
              <el-option :label="$t('user.normal')" :value="2"></el-option>
              <el-option :label="$t('user.poor')" :value="3"></el-option>
              <el-option :label="$t('user.ill')" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('user.history')" label-width="120px" prop="allergies">
            <el-input v-model="userForm.allergies"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.past')" label-width="120px" prop="medical_history">
            <el-input type="textarea" v-model="userForm.medical_history"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.past_medical_history')" label-width="120px" prop="past_medical_history">
            <el-input type="textarea" v-model="userForm.past_medical_history"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.smoke_year')" label-width="120px" prop="smoke_year">
            <el-input v-model="userForm.smoke_year"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.smoke_frequency')" label-width="120px" prop="smoke_frequency">
            <el-input v-model="userForm.smoke_frequency"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.drink_year')" label-width="120px" prop="drink_year">
            <el-input v-model="userForm.drink_year"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.drink_frequency')" label-width="120px" prop="drink_frequency">
            <el-input v-model="userForm.drink_frequency"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.motion_habit')" label-width="120px" prop="motion_habit">
            <el-input v-model="userForm.motion_habit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.eating_habit')" label-width="120px" prop="eating_habit">
            <el-input v-model="userForm.eating_habit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.work_rest')" label-width="120px" prop="work_rest">
            <el-input v-model="userForm.work_rest"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.supplement_info')" label-width="120px" prop="supplement_info">
            <el-input type="textarea" v-model="userForm.supplement_info"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.drug')" label-width="120px" prop="drug_history">
            <el-input type="textarea" v-model="userForm.drug_history"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.remark')" label-width="120px" prop="remark">
            <el-input type="textarea" v-model="userForm.remark"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.emergency')" label-width="120px" prop="urgent_name">
            <el-input v-model="userForm.urgent_name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.urgent_relation')" label-width="120px" prop="urgent_relation">
            <el-input v-model="userForm.urgent_relation"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.call_emergency')" label-width="120px" prop="urgent_mobile">
            <el-input v-model="userForm.urgent_mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.current_address')" label-width="120px" prop="current_address">
            <el-input v-model="userForm.current_address"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="userinfoPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="userinfoAffirm" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog :title="$t('group.alarm_setting')" v-model="setAlarmDialog" center top="30vh">
        <el-form :model="alarmForm" label-width="180px">
          <el-form-item :label="$t('detail.heart')+'('+$t('user.range')+')'" prop="content">
            <div style="display:flex;">
              <el-input v-model="alarmForm.heart_min" style="width:120px;" placeholder="最小值"></el-input>
              <span style="margin:0 10px;">_</span>
              <el-input v-model="alarmForm.heart_max" style="width:120px;" placeholder="最大值"></el-input>
              <span style="margin:0 10px;">bpm</span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('detail.systolic')+'('+$t('user.range')+')'" prop="content">
            <div style="display:flex;">
              <el-input v-model="alarmForm.diastolic_min" style="width:120px;"
                        :placeholder="$t('group.min')"></el-input>
              <span style="margin:0 10px;">_</span>
              <el-input v-model="alarmForm.diastolic_max" style="width:120px;"
                        :placeholder="$t('group.max')"></el-input>
              <span style="margin:0 10px;">mmHg</span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('detail.diastolic')+'('+$t('user.range')+')'" prop="content">
            <div style="display:flex;">
              <el-input v-model="alarmForm.systolic_min" style="width:120px;" :placeholder="$t('group.min')"></el-input>
              <span style="margin:0 10px;">_</span>
              <el-input v-model="alarmForm.systolic_max" style="width:120px;" :placeholder="$t('group.max')"></el-input>
              <span style="margin:0 10px;">mmHg</span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('detail.temp')+'('+$t('user.range')+')'" prop="content">
            <div style="display:flex;">
              <el-input v-model="alarmForm.temp_min" style="width:120px;" :placeholder="$t('group.min')"></el-input>
              <span style="margin:0 10px;">_</span>
              <el-input v-model="alarmForm.temp_max" style="width:120px;" :placeholder="$t('group.max')"></el-input>
              <span style="margin:0 10px;">℃</span>
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="setAlarmDialog = false">{{ $t('device.cancel') }}</el-button>
            <el-button type="primary" @click="alarmSetConfirm">{{ $t('device.confirm') }}</el-button>
          </span>
          <p style="margin-top:20px;">{{ $t('group.alarm_note') }}</p>
        </template>
      </el-dialog>
      <el-dialog center :title="$t('device.add_user')" top="30vh" v-model="userPop" width="30%">
        <el-form :model="infoForm" ref="infoForm">
          <el-form-item
              :label="$t('index.username')"
              label-width="120px"
              prop="name"
          >
            <el-input v-model="infoForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.contact')" label-width="120px" prop="mobile">
            <el-input v-model="infoForm.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('index.device')" label-width="120px" prop="imei">
            <el-input v-model="infoForm.imei"></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('device.vesting')"
              label-width="120px"
              prop="client_id"
          >
            <el-cascader
                :options="options"
                :placeholder="$t('device.vesting')"
                v-model="infoForm.client_id"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('index.device_type')" label-width="120px" prop="type_id">
            <el-select v-model="infoForm.type_id" :placeholder="$t('index.device_type')" clearable>
              <el-option
                  v-for="item in deviceType"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="userPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="userAffirm" type="primary">{{ $t('device.confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20,50]"
          :total="pageTotal"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import userapp from "@/model/user";
import device from "@/model/device";
import alarm from "@/model/alarm";
import client from "../../model/client";

export default {
  name: "userApp",
  data() {
    return {
      pageTotal: 0,
      formInline: {
        name: "",
        imei: "",
        client_id: "",
        page: 1,
        size: 10,
        order: {}
      },
      userPop: false,
      unbindPop: false,
      userinfoPop: false,
      deviceType: [],
      listData: [],
      typeData: [],
      userForm: {
        uid: "",
        gender: "",
        birthday: "",
        height: "",
        weight: "",
        blood_type: "",
        allergies: "",
        medical_history: "",
        drug_history: "",
        health: "",
        urgent_name: "",
        urgent_mobile: "",
        remark: ""
      },
      infoForm: {
        uid: "",
        name: "",
        mobile: "",
        imei: "",
        client_id: "",
        type_id: ""
      },
      alarmForm: {
        uid: "",
        temp_min: "",
        temp_max: "",
        heart_min: "",
        heart_max: "",
        systolic_min: "",
        systolic_max: "",
        diastolic_min: "",
        diastolic_max: ""
      },
      options: [],
      user_list: "",
      selectItemIds: [],
      unbindIsDisabled: true,
      setAlarmDialog: false,
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      orderParam: {
        prop: "",
        order: ""
      }
    };
  },
  async mounted() {
    await this.clientList();
    await this.appuserList();
    await this.deviceTypeArr();
  },

  methods: {
    async appuserList() {
      this.formInline.order = this.orderParam
      const ret = await userapp.list(this.formInline);
      if (ret.code === 200) {
        this.listData = ret.data.list;
        this.pageTotal = ret.data.count;
      }
    },
    sortChange(column) {
      if (column.prop === 'volt') {
        this.orderParam.prop = 'device.' + column.prop;
      } else {
        this.orderParam.prop = 'user.' + column.prop;
      }
      this.orderParam.order = column.order;
      this.appuserList();
    },
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async clientList() {
      const ret = await client.list();
      if (ret.code === 200) {
        this.options = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    addUsers() {
      this.userPop = true;
      this.infoForm = {
        uid: "",
        name: "",
        mobile: "",
        imei: "",
        client_id: "",
        type_id: ""
      }
    },
    handleEdit(data) {
      this.userPop = true;
      this.infoForm = data;
    },
    healthEdit(imei) {
      this.$router.push({
        path: "/data/health",
        query: {
          imei: imei
        }
      });
    },
    locationEdit(imei) {
      this.$router.push({
        path: "/data/location",
        query: {
          imei: imei
        }
      });
    },
    trackEdit(imei) {
      this.$router.push({
        path: "/data/track",
        query: {
          imei: imei
        }
      });
    },
    fenceEdit(imei) {
      this.$router.push({
        path: "/data/fence",
        query: {
          imei: imei
        }
      });
    },
    async usermesEdit(uid) {
      this.userinfoPop = true;
      const ret = await userapp.profile({uid: uid});
      if (ret.code === 200) {
        this.userForm = ret.data;
      } else {
        this.$message.error(ret.message);
        this.userinfoPop = false;
      }
    },
    unbindEdit(uid) {
      this.unbindPop = true;
      this.user_list = uid;
    },
    deleteUser(uid) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await userapp.deleteUserInfo({
              uid: uid
            });
            if (ret.code === 200) {
              this.$message.success(this.$t('device.delete_success'));
              this.appuserList();
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    async userinfoAffirm() {
      const ret = await userapp.operateInfo(this.userForm);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.userinfoPop = false;
        this.appuserList();
      } else {
        this.$message.error(ret.message);
      }
    },
    userAffirm() {
      this.$refs.infoForm.validate(async (valid) => {
        if (valid) {
          const infoForm = JSON.parse(JSON.stringify(this.infoForm))
          infoForm.client_id = infoForm.client_id instanceof Array ? infoForm.client_id[infoForm.client_id.length - 1] : infoForm.client_id
          const ret = await userapp.operate(infoForm);
          if (ret.code === 200) {
            this.$message.success(ret.message);
            this.userPop = false;
            this.appuserList();
          } else {
            this.$message.error(ret.message);
          }
        } else {
          return false;
        }
      });
    },
    async unbindAffirm() {
      const ret = await userapp.untie({
        user_list: this.user_list
      });
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.unbindPop = false;
      }
    },
    searchCont() {
      this.appuserList();
    },
    resetBtn() {
      this.$refs.searchform.resetFields();
      this.appuserList();
    },
    //解绑
    async batchUnbind() {
      const ret = await userapp.untie({
        user_list: this.selectItemIds.toString()
      });
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.$refs.userlistTable.clearSelection();
        this.appuserList();
      }
    },
    //设置报警信息
    async setAlarmFun() {
      const ret = await alarm.setting(this.alarmForm);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.setAlarmDialog = false;
      } else {
        this.$message.error(ret.message);
      }
    },
    tableSelected(val) {
      this.selectItemIds = [];
      for (let selectedItem of val) {
        this.selectItemIds.push(selectedItem.uid);
        this.unbindIsDisabled = false;
      }
    },
    async alarmsetEdit(id) {
      // this.alarmForm.uid = id;
      this.setAlarmDialog = true;
      const ret = await alarm.get_setting({
        uid: id
      });
      if (ret.code === 200) {
        this.alarmForm = ret.data
      } else {
        this.$message.error(ret.message);
      }
      // this.alarmForm = {
      //   uid: id,
      //   temp_min: "",
      //   temp_max: "",
      //   heart_min: "",
      //   heart_max: "",
      //   systolic_min: "",
      //   systolic_max: "",
      //   diastolic_min: "",
      //   diastolic_max: ""
      // }
    },
    alarmSetConfirm() {
      this.setAlarmFun();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.appuserList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.appuserList();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}
</style>

